import { useContext, createContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "lib/firebase";
import axios from "axios";

const ChatContext = createContext();

export function useChat() {
  return useContext(ChatContext);
}

export default function ChatProvider({ mode, children }) {
  const { user } = useAuth();
  const docId = `${user?.uid}-${mode.key}`;
  const docRef = doc(db, "bot-chats", docId);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
    const options = query(
      collection(docRef, "messages"),
      limit(25),
      orderBy("timestamp")
    );
    getDocs(options).then((snapshot) => {
      setMessages(snapshot.docs.map((doc) => doc.data()));
    });
  }, [docRef]);

  const saveMessagesToDb = (docRef,...messages) => {
    const conversationRef = collection(docRef, "messages");
    return Promise.all(
      messages.map(async (message) => {
        return await addDoc(conversationRef, message);
      })
    );
  };

  const sendMessage = async (text, chatbotMessage, docRef) => {
		setMessages((messages) => [...messages, text]);
		setMessages((messages) => [...messages, chatbotMessage]);
		saveMessagesToDb(docRef, text, chatbotMessage);
  };

  const value = {
    messages,
    mode,
    sendMessage,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
