import ReactMarkdown from 'react-markdown';
import CopyToClipboadButton from './CopyToClipboardButton';
import DownloadImageButton from './DownloadImage';
import RegenerateButton from './Regenerate';
import CodeBlock from './CodeBlock';
import { Row, Col, Card, CardBody, Modal } from 'reactstrap';
import { IoShareOutline } from 'react-icons/io5';
import { useEffect, useState } from 'react';

export default function Message({
	from,
	content,
	loading,
	typing,
	image,
	isCreativeImageMode,
	creativeContent,
	shareCallback,
	handleRegenerate,
	setLoadedImages,
	isShare,
	isShareMode,
	isShareComponent,
	childMessage,
	childIndex,
	handleCheckedMessage,
	setBottomShare,
}) {
	// states
	const [isChecked, setIsChecked] = useState(true);

	const fromUser = from === 'student';
	const [width, setWidth] = useState(window.innerWidth);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleImageClick = () => {
		setIsModalOpen(true);
	};

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const handleMessage = () => {
		setIsChecked((prev) => !prev);
		handleCheckedMessage(childMessage, isChecked, childIndex);
	};

	const isMobile = width <= 450;
	const isDownloadMobbile = width <= 769;
	return (
		<Row
			className={`message  mb-3 ${fromUser ? 'student' : 'chatbot'}`}
			onClick={() => {
				if (!isShare) {
					handleMessage();
				}
			}}
		>
			<style>
				{`
				a {
					color: rgba(255, 255, 255, 0.8);
				}
				`}
			</style>
			<Col className="m-0 col-auto message-body ">
				<input type="checkbox" id={`message-checkbox-${content}`} />
				<label htmlFor={`message-checkbox-${content}`}></label>

				<Card
					className={fromUser ? 'bg-student' : 'bg-primary'}
					style={{
						height: 'fit-content',
						maxHeight: 'fit-content',
						fontWeight: '300',
						maxWidth: isMobile ? '80vw' : '',
						width: isMobile ? 'fit-content' : '',
					}}
				>
					{isCreativeImageMode ? (
						<CardBody className="p-0">
							{loading ? (
								<div className="d-flex justify-content-center align-items-center w-100 p-0" style={{ minHeight: '7px' }}>
									<div className="spinner">
										<div className="bounce1" />
										<div className="bounce2" />
										<div className="bounce3" />
									</div>
								</div>
							) : (
								<>
									<img
										src={creativeContent}
										className="img-fluid"
										alt="Creative minds think alike"
										onClick={handleImageClick}
										onLoad={() =>
											setLoadedImages((prev) => prev + 1)
										}
									/>
									<div
										className="hello"
										style={{
											backgroundColor: 'lightgray',
											width: '100% !important',
											height: '100% !important',
										}}
									>
										<Modal
											isOpen={isModalOpen}
											fullscreen={true}
											centered
											onRequestClose={() =>
												setIsModalOpen(false)
											}
											size="lg"
											modalClassName="modal-dark-blue custom-height-modal"
											style={{
												overlay: {
													backgroundColor:
														'rgba(225,225,0,0.5)',
												},
												content: {
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													position: 'relative',
												},
											}}
										>
											<button
												onClick={() =>
													setIsModalOpen(false)
												}
												style={{
													position: 'absolute',
													top: '10px',
													right: '10px',
													background: 'black',
													border: 'none',
													color: 'white',
													borderRadius: '50%',
													padding: '.5rem .7rem',
												}}
											>
												X
											</button>
											<img
												src={creativeContent}
												style={{
													height: '80vh',
													width: '100%',
												}}
											/>
										</Modal>
									</div>
									<ReactMarkdown
										components={{ code: CodeBlock }}
										className={`${
											content ? 'pl-2 pb-2' : ''
										}`}
									>
										{content}
									</ReactMarkdown>
								</>
							)}{' '}
						</CardBody>
					) : (
						<CardBody
							className={`
						${fromUser ? 'p-2' : 'p-2'}
						`}
							style={{ lineHeight: '1.2em' }}
						>
							{loading ? (
								<div className="d-flex justify-content-center align-items-center w-100 p-0" style={{ minheight: '7px' }}>
									<div className="spinner">
										<div className="bounce1" />
										<div className="bounce2" />
										<div className="bounce3" />
									</div>
								</div>
							) : (
								<>
									<ReactMarkdown
										components={{
											code: CodeBlock,
										}}
									>
										{content}
									</ReactMarkdown>
								</>
							)}{' '}
						</CardBody>
					)}
				</Card>

				{isCreativeImageMode
					? !fromUser &&
					  !typing &&
					  !isShareMode && (
							<div className="btnContainer">
								<div className="shareContainer ml-0">
									<DownloadImageButton
										isDownloadMobbile={isDownloadMobbile}
										imageUrl={creativeContent}
										text="Download"
									/>
								</div>
								<div
									className="shareContainer mb-md-4"
									onClick={() => {
										setBottomShare('yes');
										shareCallback();
									}}
								>
									<IoShareOutline
										style={{ fontSize: '1.8vh' }}
									/>{' '}
									Share
								</div>
								<div className="shareContainer">
									<RegenerateButton
										text="Regenerate"
										handleRegenerate={handleRegenerate}
									/>
								</div>
							</div>
					  )
					: !fromUser &&
					  !typing &&
					  !isShareMode && (
							<div className="btnContainer text-left">
								<div>
									{!isShare && (
										<CopyToClipboadButton
											content={content}
											text="Copy"
										/>
									)}
								</div>
								{shareCallback && (
									<div
										className="copy-answer ml-3 mb-md-4"
										onClick={() => {
											setBottomShare('yes');
											shareCallback();
										}}
									>
										Share
										<IoShareOutline
											style={{ fontSize: '1.8vh' }}
										/>
									</div>
								)}
							</div>
					  )}
			</Col>
		</Row>
	);
}
